import { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { baseUrl } from "./components/authentication/authorization";
import Navbar from "./components/static/navbar";
import Footer from "./components/static/footer";
import Home from "./components/home/home";
import Services from "./components/services/services";
import Gallery from "./components/gallery/gallery";
import AboutUs from "./components/about-us/about-us";
import ContactUs from "./components/contact-us/contact-us";
import Menu from "./components/menu/menu";
import Login from "./components/authentication/login";
import Register from "./components/authentication/register";
import PageNotFound from "./components/page-not-found";
import Cart from "./components/cart/cart";
import ForgotPassword from "./components/authentication/forgot-password";
import PaymentSuccess from "./components/authentication/payment-success";
import BannerButton from "./components/common/BannerButton";
import OrderOnline from "./components/common/order-online";
import HalalFood from "./components/common/HalalFoodButton";
import Cookies from "./cookies";
import "./assets/css/scrollbar.css";

function App(props) {
  const { loggedIn, role } = props;
  const sessionId = localStorage.getItem("sessionId");

  let cart = localStorage.getItem("cart");
  if (
    cart !== undefined &&
    typeof JSON.parse(cart) === "object" &&
    JSON.parse(cart) !== null
  ) {
    cart = JSON.parse(cart);
  } else {
    cart = {
      items: [],
      total: 0,
    };
  }

  const [totalItems, setTotalItems] = useState(cart.total);

  const [details, setDetails] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetch(`${baseUrl}/site/settings/`, {
      method: "GET",
      signal,
    })
      .then((response) => response.json())
      .then((dta) => {
        if (Array.isArray(dta) && dta.length > 0) {
          setDetails({ ...dta[0] });
        }
      })
      .catch(() => null);

    return () => controller.abort();
  }, []);

  if (loggedIn) {
    return (
      <div>
        <BannerButton />
        {/* <HalalFood /> */}
        <OrderOnline />
        <Navbar loggedIn={loggedIn} role={role} total={totalItems} />
        <Switch>
          <Route exact path="/" render={() => <Home {...details} />} />
          <Route exact path="/page-not-found" component={PageNotFound} />
          <Route
            exact
            path="/menu-list"
            render={(props) => (
              <Menu
                loggedIn={loggedIn}
                setTotalItems={setTotalItems}
                {...props}
              />
            )}
          />
          <Route exact path="/services" component={Services} />
          <Route exact path="/gallery" component={Gallery} />
          <Route
            exact
            path="/about-us"
            render={() => <AboutUs {...details} />}
          />
          <Route exact path="/contact-us" component={ContactUs} />
          {sessionId && (
            <Route path="/cart/success" component={PaymentSuccess} />
          )}
          <Route
            exact
            path="/cart"
            render={(props) => (
              <Cart
                {...props}
                setTotalItems={setTotalItems}
                tax={details.tax_per}
              />
            )}
          />
          <Redirect to="/page-not-found"></Redirect>
        </Switch>
        <Footer {...details} />
        <MessengerCustomerChat
          pageId={process.env.REACT_APP_MESSENGER_PAGE_ID}
          appId={process.env.REACT_APP_MESSENGER_APP_ID}
          themeColor="#CC8125"
        />
        <Cookies />
      </div>
    );
  }

  return (
    <div>
      <BannerButton />
      {/* <HalalFood /> */}
      <OrderOnline />
      <Navbar loggedIn={loggedIn} role={role} />
      <Switch>
        <Route exact path="/" render={() => <Home {...details} />} />
        <Route exact path="/page-not-found" component={PageNotFound} />
        <Route
          exact
          path="/menu-list"
          render={(props) => (
            <Menu
              loggedIn={loggedIn}
              {...props}
              setTotalItems={setTotalItems}
            />
          )}
        />
        <Route exact path="/services" component={Services} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/about-us" render={() => <AboutUs {...details} />} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Redirect to="/page-not-found" />
      </Switch>
      <Footer {...details} />
      <MessengerCustomerChat
        pageId="136112710770666"
        appId="225135542615214"
        themeColor="#CC8125"
      />
      <Cookies />
    </div>
  );
}

export default App;
